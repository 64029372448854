var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"experience"}},[_c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-column my-5"},[_c('p',{staticClass:"title"},[_vm._v("July 2021 - Present")]),_c('p',{staticClass:"headline primary--text",staticStyle:{"font-family":"Francois One !important"}},[_vm._v(" Quantitative Developer @ NextGateTech ")]),_c('p',{class:[
              'ma-0',
              'primary--text',
              _vm.$vuetify.breakpoint.lgAndDown ? 'subtitle-1' : 'title'
            ]},[_vm._v(" Next Gate Tech leverages the latest cloud technology to give clients all their data from multiple unharmonized/unstructured sources, as one consistent, consolidated, and independent source enabling them to streamline their day-to-day processes. ")]),_c('v-divider',{staticClass:"my-5"}),_c('ul',{staticClass:"pa-0",staticStyle:{"list-style-type":"none"}},_vm._l((_vm.ngt_experience),function(item){return _c('li',{key:item,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"deep-orange accent-4"}},[_vm._v("mdi-check-circle")]),_c('span',{class:[
                    'ma-0',
                    'primary--text',
                    _vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'
                  ]},[_vm._v(_vm._s(item))])],1)])}),0)],1),_c('div',{staticClass:"h-divider"},[_c('div',{staticClass:"shadow"}),_c('div',{staticClass:"text2"},[_c('img',{attrs:{"src":require("@/assets/divider.png")}})])]),_c('br'),_c('div',{staticClass:"d-flex flex-column my-5"},[_c('p',{staticClass:"title"},[_vm._v("January 2018 - June 2021")]),_c('p',{staticClass:"headline primary--text",staticStyle:{"font-family":"Francois One !important"}},[_vm._v(" Software & Data Developer @ Alphacruncher ")]),_c('p',{class:[
              'ma-0',
              'primary--text',
              _vm.$vuetify.breakpoint.lgAndDown ? 'subtitle-1' : 'title'
            ]},[_vm._v(" Alphacruncher AG, a spin-off from a Swiss university, is a technology company providing professional, scientific and technical services. Alphacruncher’s core objective is to boost scientific research and education by integrating cutting edge advances in computation, data and cloud technology. In 2019 Alphacruncher launched "),_c('a',{staticClass:"cyan--text",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://nuvolos.cloud/","target":"_blank"}},[_vm._v("Nuvolos")]),_vm._v(", the knowledge creation cloud, an integrated and unified cloud framework designed and built for the needs of academics. ")]),_c('v-divider',{staticClass:"my-5"}),_c('ul',{staticClass:"pa-0",staticStyle:{"list-style-type":"none"}},_vm._l((_vm.ac_experience),function(item){return _c('li',{key:item,staticClass:"mb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"deep-orange accent-4"}},[_vm._v("mdi-check-circle")]),_c('span',{class:[
                    'ma-0',
                    'primary--text',
                    _vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'
                  ]},[_vm._v(_vm._s(item))])],1)])}),0)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }